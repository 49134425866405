<template>
  <form class="section-request-code" action="#" :class="{ 'is-disabled': disabled }" @submit.prevent="!this.errorText ? $emit('submit') : ''">
    <div class="number-zone" @click="$refs.input.focus()">
      <p class="number-only">Mobile number (including country code)</p>
      <div class="phone-number">
        <input
          ref="input"
          type="tel"
          name="tel"
          autocomplete="tel"
          maxlength="15"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :disabled="disabled"
          pattern="[0-9+]*"
        />
      </div>
    </div>
    <transition name="fade">
      <div v-if="errorText" class="error-text" v-text="errorText" />
    </transition>
  </form>
</template>

<script>
export default {
  name: 'KlubbaRequestCode',
  props: {
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: String,
    },
    errorText: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$refs.input.focus();
  },
};
</script>

<style lang="sass" scoped>
.section-request-code
  @apply px-8 pt-8 block
  transition: opacity var(--transition)

  &.is-disabled
    @apply opacity-30 pointer-events-none

.number-only
  @apply text-black-light text-sm leading-4 tracking-spacing-7 pb-2 relative
  &::after
    content: ' *'
    @apply text-danger

.number-zone
  @apply bg-white py-3 px-4 rounded-radius-10

.error-text
  @apply text-danger mt-2
  font-size: 14px
  line-height: 1.5

.phone-number
  @apply text-xl flex

  input
    @apply flex-1 w-0
    @apply focus:outline-none cursor-pointer text-black-dark leading-6 font-medium
    background-color: transparent

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
</style>
