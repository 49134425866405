<template>
  <div class="page-request-code page-content">
    <KlubbaDarkBgText>
      <template #main>
        <p>{{ club.name }} uses KLUBBA to manage subscriptions.
          It’s fast an easy to create a KLUBBA account
          and you can use it to manage your subscriptions
          and memberships across multiple different organisations.</p>
      </template>
      <template #accent>
        <p>Enter your mobile number to receive your unlock code:</p>
      </template>
      <template #links>
        <p @click="isTermsVisible = true">Terms and conditions</p>
        <p @click="isPrivacyVisible = true">Privacy policy</p>
      </template>
    </KlubbaDarkBgText>

    <klubba-request-code
      :disabled="loading"
      v-model="phoneNumber"
      @submit="requestCode"
      :errorText="errorText"
    />

    <div class="button-wrap">
      <klubba-button
        @click="requestCode"
        buttonText='Request unlock code'
        :customClass='customClass'
        :loading="loading"
        :disabled="disableButton"
      />
    </div>

    <!-- Modals -->
    <BaseModal :active="isTermsVisible" @close="isTermsVisible = false">
      <KlubbaRichText v-html="club.terms_and_conditions" />
    </BaseModal>

    <BaseModal :active="isPrivacyVisible" @close="isPrivacyVisible = false">
      <KlubbaRichText v-html="club.privacy_policy" />
    </BaseModal>
  </div>
</template>

<script>
import swal from 'sweetalert';
import firebase from 'firebase';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaRequestCode from '@/components/custom/KlubbaRequestCode.vue';
import BaseModal from '@/components/BaseModal.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';
import KlubbaDarkBgText from '@/components/custom/KlubbaDarkBgText.vue';

export default {
  components: {
    KlubbaDarkBgText,
    KlubbaRichText,
    KlubbaButton,
    KlubbaRequestCode,
    BaseModal,
  },
  data() {
    return {
      loading: false,
      customClass: 'bg-primary',
      isTermsVisible: false,
      isPrivacyVisible: false,
      phoneNumber: '',
    };
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    disableButton() {
      return this.phoneNumber.length < 5 || !this.phoneNumber.includes('+');
    },
    phoneNumberValid() {
      return /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(this.phoneNumber);
    },
    errorText() {
      return this.phoneNumber.length >= 5 && !this.phoneNumberValid ? 'Numbers must start with the full international country calling code, e.g. +61420123456' : '';
    },
  },
  methods: {
    requestCode() {
      this.loading = true;

      // Start firebase auth
      const appVerifier = window.recaptchaVerifier;

      // Re-init recaptcha
      if (!appVerifier?.type) {
        swal('Error', 'Please try again', 'error');
        this.loading = false;
        return;
      }

      firebase
        .auth()
        .signInWithPhoneNumber(this.phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;

          // Save info to Vuex
          this.$store.commit('setNewUser', { phone_number: this.phoneNumber });

          // Redirect
          this.$router.push({
            path: `/${this.club.id}/enter-code`,
            query: this.$route.query,
          });

          // Remove loading state
          this.loading = false;
        }).catch((error) => {
          swal('Error', error.message, 'error');
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.button-wrap
  @apply p-8 mt-auto
</style>
